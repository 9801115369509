import { FC } from 'react'
import { Label } from './Label'
import { Input } from './Input'
import { TextArea } from './TextArea'
import { SubmitButton } from './SubmitButton'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size } from 'src/utils/constants'

export const Form: FC = () => {
  return (
    <form
      name="contact"
      method="POST"
      action="/contact/success/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      css={rootStyle}
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <p>
        <Label htmlFor="name" text="お名前（会社名）" />
        <Input id="name" type="text" name="name" />
      </p>
      <p>
        <Label htmlFor="email" text="メールアドレス" />
        <Input id="email" type="email" name="email" />
      </p>
      <p>
        <Label htmlFor="message" text="お問い合わせ内容" />
        <TextArea id="message" name="message" rows={10} />
      </p>
      <p>
        <SubmitButton text="送信する" />
      </p>
    </form>
  )
}

const rootStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${size.gutter.m}rem 0;
`
