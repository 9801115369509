import { FC } from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { color, size } from 'src/utils/constants'

type Props = {
  name: string
  id: string
  value?: string
  rows?: number
  cols?: number
}

export const TextArea: FC<Props> = ({ name, value, id, rows, cols }) => {
  return (
    <textarea
      id={id}
      name={name}
      value={value}
      rows={rows}
      cols={cols}
      css={rootStyle}
      required
    />
  )
}

const rootStyle = css`
  border: ${size.border.s}rem solid ${color.gray};
  padding: 0.5rem;
  font-size: 16px;
  background-color: ${color.gray};
  transition: border ${size.delay.s}s ease-out;
  &:hover,
  &:focus {
    border: ${size.border.s}rem solid ${color.border};
  }
`
