import { FC } from 'react'
import { Layout } from 'src/components/site/Layout'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { Paragraph } from 'src/components/parts/Paragraph'
import { Breadcrumb } from 'src/components/groups/Breadcrumb'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { PageHeading } from 'src/components/parts/PageHeading'
import { TextLink } from 'src/components/parts/TextLink'
import { Form } from 'src/components/parts/Form'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

const ContactPage: FC = () => {
  const breadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: 'CONTACT',
      path: '/contact/',
    },
  ]
  return (
    <Layout
      title="CONTACT"
      description="KOKONIARUKOTO.へのご依頼、お問い合わせはこちらのページのメールフォームからどうぞ。"
      noindex
      ldType="Contact"
    >
      <Section>
        <Container>
          <PageHeading heading="CONTACT" />
        </Container>
      </Section>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Section>
        <Container small>
          <SectionHeading heading="お問い合わせ" />
          <Paragraph>
            <TextLink
              to="/privacy-policy/"
              text="プライバシーポリシーを確認"
              css={{ marginRight: 'auto' }}
            />
          </Paragraph>
          <Form />
        </Container>
      </Section>
    </Layout>
  )
}

export default ContactPage
