import { FC } from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size, color } from 'src/utils/constants'

type Props = {
  text: string
}

export const SubmitButton: FC<Props> = ({ text }) => {
  return (
    <button type="submit" css={rootStyle}>
      {text}
    </button>
  )
}

const rootStyle = css`
  display: block;
  max-width: max-content;
  padding: 1.5rem;
  line-height: 1;
  box-shadow: ${size.shadow.s}rem ${size.shadow.s}rem ${color.shadow};
  position: relative;
  background-color: ${color.text};
  transition: box-shadow ${size.delay.s}s ease-out,
    transform ${size.delay.s}s ease-out,
    background-color ${size.delay.s}s ease-out;
  font-weight: ${size.weight.m};
  color: ${color.bg};
  &:hover,
  &:focus {
    transform: translate(0, -0.1rem);
    background-color: ${color.shadow};
    box-shadow: 0rem 0rem ${color.shadow};
  }
`
