import { FC } from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { color, size } from 'src/utils/constants'

type Props = {
  text: string
  htmlFor: string
}

export const Label: FC<Props> = ({ text, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} css={rootStyle}>
      {text}
    </label>
  )
}

const rootStyle = css`
  color: ${color.shadow};
  line-height: ${size.lineHeight.l};
`
