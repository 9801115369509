import { FC } from 'react'
import { Link } from 'gatsby'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size, color } from 'src/utils/constants'

type Props = {
  text: string
  to: string
}

export const TextLink: FC<Props> = ({ to, text, ...props }) => {
  return (
    <Link to={to} css={rootStyle} {...props}>
      {text}
    </Link>
  )
}

const rootStyle = css`
  display: block;
  max-width: max-content;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  line-height: 1;
  box-shadow: ${size.shadow.s}rem ${size.shadow.s}rem ${color.shadow};
  position: relative;
  background-color: ${color.gray};
  transition: box-shadow ${size.delay.s}s ease-out,
    transform ${size.delay.s}s ease-out, color ${size.delay.s}s ease-out;
  font-weight: ${size.weight.m};
  &:hover,
  &:focus {
    color: ${color.shadow};
    transform: translate(0, -0.1rem);
    box-shadow: 0rem 0rem ${color.shadow};
    &::before {
      border-top: 0.5rem solid transparent;
      border-left: 0.5rem solid ${color.shadow};
      border-bottom: 0.5rem solid transparent;
    }
  }
  &::before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    margin: auto;
    border-top: 0.5rem solid transparent;
    border-left: 0.5rem solid ${color.text};
    border-bottom: 0.5rem solid transparent;
    transition: border ${size.delay.s}s ease-out;
  }
`
