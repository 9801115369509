import { FC } from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { color, size } from 'src/utils/constants'

type Props = {
  type: string
  name: string
  id: string
  value?: string
}

export const Input: FC<Props> = ({ type, name, value, id }) => {
  return (
    <input
      id={id}
      type={type}
      name={name}
      value={value}
      css={rootStyle}
      required
    />
  )
}

const rootStyle = css`
  border: ${size.border.s}rem solid ${color.gray};
  padding: 0.5rem;
  font-size: 16px;
  width: 50%;
  background-color: ${color.gray};
  transition: border ${size.delay.s}s ease-out, width ${size.delay.s}s ease-out;
  &:hover {
    border: ${size.border.s}rem solid ${color.border};
  }
  &:focus {
    width: 100%;
    border: ${size.border.s}rem solid ${color.border};
  }
`
